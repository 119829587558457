import * as icon from '../assets/Icons'
import SecurityHelper from '../helpers/SecurityHelper';
import ConferenceHelper from '../helpers/ConferenceHelper';
import PackageHelper from '../helpers/PackageHelper';
import scheduleHelper from '../helpers/ScheduleHelper';

import {
  ROLE_ADMIN_ID,
  ROLE_ATTENDEE_ID,
  ROLE_EXHIBITOR_ADMIN_ID,
  ROLE_MODERATOR_ID,
  ROLE_OPERATOR_ID,
  ROLE_PRESENTER_ID
} from '../helpers/RoleHelper';
import planHelper from '../helpers/PlanHelper';

// Determines the navigation menu items for administrators, moderators, operators, and presenters
export default function administrator() {
  return [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: icon.dashboard,
      alwaysIcon: true
    },
    {
      name: SecurityHelper.isAdministrator() ? 'Pre-Conference' : 'Conference',
      icon:  SecurityHelper.isAdministrator() ? icon.double_check  : icon.conference,
      tag: "conference",
      visible: !ConferenceHelper.isConferenceExpired(),
      children: [
        {
          name: 'Setup',
          url: '/conference',
          icon: icon.setup,
          visible: ["conference.save"]
        },
        {
          name: 'Landing Page',
          url: '/conference/setupcustomconference',
          icon: icon.edit,
          visible: ["conference.save"]
        },
        {
          name: 'Maps',
          url: '/maps',
          icon: icon.map,
          visible: SecurityHelper.canExecuteAction("map.list")
        }, 
        {
          name: 'Schedule at a Glance',
          url: '/schedule',
          icon: icon.calendar_week,
          visible: () => {
            // One of two conditions must be met for viewing. 1. Role of Admin OR 
            // 2. Role NOT Admin, but schedule is published
            return SecurityHelper.isAdministrator() || (!SecurityHelper.isAdministrator() && scheduleHelper.publicationStatus)
          }
        }, 
        // We have renamed Contacts to Prospects. We are changing only the labels. So please try not to change paths, file names or similar issues
        {
          name: 'Prospects',
          url: '/prospects',
          icon: icon.contact,
          visible: ["contact.save"],
          lock: !PackageHelper.isPreConferenceEnabled() && SecurityHelper.isAdministrator()
        },        
      ]
    },    
    {
      name: 'Registration',
      icon: icon.online_registration,
      tag: "registrations", 
      children: [ 
        {
          name: 'Attendee Registration',
          url: '/users/registrations/attendee',
          icon: icon.online_registration,
          visible: ["registration.list"],
          lock: (!PackageHelper.isPreConferenceEnabled() && !PackageHelper.isRegstrationPackageEnabled()) && SecurityHelper.isAdministrator()
        },
        {
          name: 'Exhibitor Registration',
          url: '/users/registrations/exhibitor',
          icon: icon.edit,
          lock: (!PackageHelper.isPreConferenceEnabled() && !PackageHelper.isRegstrationPackageEnabled()) && PackageHelper.isExhibitorEnabled() && SecurityHelper.isAdministrator(),
          visible: SecurityHelper.canExecuteAction("registration.list")
        },   
        {
          name: 'Discount Coupons',
          url: '/discountcodes',
          icon: icon.discount_code,
          visible: () => SecurityHelper.canExecuteAction("discountcode.list") && !ConferenceHelper.isConferenceExpired()
        },
        {
          name: 'Invoices',
          icon: icon.invoice,
          tag: "invoices",
          url: '/templates/invoice',
          visible: ConferenceHelper.isFeatureEnabled("invoices") && !ConferenceHelper.isConferenceExpired() && SecurityHelper.isAdministrator(),
          lock: !PackageHelper.isPreConferenceEnabled() && SecurityHelper.isAdministrator() 
        },
        {
          name: 'Session Reg. Transactions',
          url: '/workshop/transactions',
          icon: icon.list,
          visible: SecurityHelper.isAdministratorOnly() && !planHelper.isExpoPlan()
        }
      ],
    },
    {
      name: 'Sessions', 
      visible: () => SecurityHelper.canExecuteAction("workshop.list") && !planHelper.isExpoPlan(),
      tag: "conference",
      icon: icon.workshop, 
      children: [
        {
          name: SecurityHelper.isAdministrator() ? 'Manage Sessions' : 'Sessions',
          url: '/sessions',
          icon: icon.workshop,
          visible: SecurityHelper.canExecuteAction("workshop.list")
        },
        {
          name: 'Session Proposals',
          url: '/sessions/proposals',
          icon: icon.file,
          lock: !PackageHelper.isPreConferenceEnabled() && SecurityHelper.isAdministrator(),
          visible: () => SecurityHelper.canExecuteAction("workshopproposal.list")
        },
        {
          name: 'Session QR',
          url: '/sessionqr',
          icon: icon.print_qr,
          visible: ["workshop.getqr"],
          lock: !PackageHelper.isAttendeeEnabled() && SecurityHelper.isAdministrator()
        },
        {
          name: 'Presenters',
          url: '/users/presenter',
          icon: icon.presenter,
          visible: () => SecurityHelper.canExecuteActionOnResource("user.list", { RolesString: ROLE_PRESENTER_ID })
        },
        {
          name: 'Presenters',
          url: '/presenters',
          icon: icon.presenter,
          visible: !SecurityHelper.isAdministrator()
        },
      ]
    },
    {
      name: 'Attendees',
      icon: icon.child,
      tag: "attendees",
      visible: !ConferenceHelper.isConferenceExpired(),
      children: [
        {
          name: 'Manage Attendees',
          url: '/users/attendee',
          icon: icon.user_edit,
          visible: () => SecurityHelper.canExecuteActionOnResource("user.list", { RolesString: ROLE_ATTENDEE_ID })
        },
        {
          name: 'Groups',
          url: '/users/groups',
          icon: icon.group,
          visible: ["usergroup.list"]
        },
        {
          name: 'Badges',
          icon: icon.badge,
          tag: "badges",
          url: '/templates/badge',
          visible: ConferenceHelper.isFeatureEnabled("badges") && !ConferenceHelper.isConferenceExpired()  && SecurityHelper.isAdministrator()
        },
        {
          name: 'Check In',
          url: '/users/checkins',
          icon: icon.check_in,
          visible: () => {
            return ConferenceHelper.isFeatureEnabled("checkin") && SecurityHelper.canExecuteAction("checkin.list")
          }
        },
        {
          name: 'Check-In Notifications',
          url: '/users/attendee/notifications',
          icon: icon.notification,
          visible: () => {
            return ConferenceHelper.isFeatureEnabled("checkin") && SecurityHelper.canExecuteAction("checkin.list")
          }
        }
      ]
    },
    {
      name: 'Exhibitors',
      icon: icon.leads,
      tag: "leads",
      lock: !PackageHelper.isExhibitorEnabled() && SecurityHelper.isAdministrator(),
      visible: !ConferenceHelper.isConferenceExpired(),
      children: [
        {
          name: 'Manage Exhibitors',
          url: '/exhibitors',
          icon: icon.company,
          visible: ["company.save"]
        },
        {
          name: 'Manage Leads',
          url: '/leads',
          icon: icon.user_edit,
          visible: "lead.list"
        },
        {
          name: 'Exhibitor Admins',
          url: '/users/exhibitor administrator',
          icon: icon.administrator,
          visible: SecurityHelper.canExecuteActionOnResource("user.list", { RolesString: ROLE_EXHIBITOR_ADMIN_ID })
        },
        {
          name: 'Exhibitor Reps',
          url: '/users/exhibitor representative',
          icon: icon.representative,
          visible: SecurityHelper.canExecuteAction("exhibitorrep.list")
        },  
        {
          name: 'Virtual Exhibit Hall',
          url: '/exhibitors/virtual',
          icon: icon.virtual_exhibit,
          visible: PackageHelper.isExhibitorEnabled() && SecurityHelper.getSettings().VirtualExhibitEnabled
        }, 
        {
          name: 'Custom Qualifiers',
          url: '/leads/form-bulk',
          icon: icon.edit,
          visible: () => SecurityHelper.canExecuteAction("lead.saveform") && !ConferenceHelper.isConferenceExpired()
        },       
        {
          name: 'Booth Reservation',
          url: '/exhibitbooth/floors',
          icon: icon.virtual_exhibit,
          visible: SecurityHelper.isAdministratorOnly()
        },
        {
          name: 'Exhibitor Purchases',
          url: '/exhibitor/purchases',
          visible: PackageHelper.isExhibitorEnabled() && SecurityHelper.canExecuteAction("registration.enable")
        },
        {
          name: 'Prizes',
          url: '/leads/rewards',
          icon: icon.awards,
          visible: SecurityHelper.canExecuteAction("lead.topattendees") || SecurityHelper.canExecuteAction("lead.randomdrawing")
        }
      ],
    }, 
    {
      name: 'Communications',
      icon: icon.presenter,
      tag: "communications", 
      visible: !SecurityHelper.isPresenterOnly(),
      children: [
        {
          name: 'Ad Center',
          url: '/ads',
          icon: icon.ad,
          lock: (!PackageHelper.isAttendeeEnabled() || !PackageHelper.isExhibitorEnabled()) && SecurityHelper.isAdministrator()
        }, 
        {
          name: 'Attendee Notifications',
          url: '/notifications',
          lock: !PackageHelper.isAttendeeEnabled() && SecurityHelper.isAdministrator(),
          icon: icon.announcement,
          visible: () => {
            return SecurityHelper.canExecuteAction("announcement.send")
          }
        },
        {
          name: 'Exhibitor Notifications',
          url: '/notifications/exhibitor',
          lock: !PackageHelper.isAttendeeEnabled() && !PackageHelper.isExhibitorEnabled() && SecurityHelper.isAdministrator(),
          icon: icon.announcement,
          visible: () => {
            return SecurityHelper.canExecuteAction("announcement.send")
          }
        },
        {
          name: 'Email Broadcast',
          url: '/users/emailbroadcast',
          icon: icon.email,
          visible: ["broadcast.email"]
        },
        {
          name: 'Staff Messaging',
          url: '/users/staffmessaging',
          icon: icon.staff_message,
          visible: () => SecurityHelper.canExecuteAction("staffmessaging.send") && !ConferenceHelper.isConferenceExpired()
        }
      ]
    },    
    {
      name: 'Attendance',
      icon: icon.attendance,
      tag: "attendance",
      visible: SecurityHelper.isAdministrator(),
      children: [
        {
          name: 'Manage Attendance',
          url: '/attendance',
          icon: icon.edit,
          visible: () => !ConferenceHelper.isConferenceExpired() &&  SecurityHelper.canExecuteAction("attendancelog.list")
        },
        {
          name: 'Check-In Stations',
          url: '/checkinstations',
          icon: icon.check_in_station,
          visible: () => {
            return !ConferenceHelper.isConferenceExpired() && ConferenceHelper.isFeatureEnabled("checkin") && SecurityHelper.canExecuteAction("checkinstation.list")
          }
        }, 
        {
          name: 'Swipe Card',
          url: '/swipes/swipecard',
          icon: icon.swipe,
          visible: () => !ConferenceHelper.isConferenceExpired() && SecurityHelper.canExecuteAction("swipe.save")
        },
        {
          name: 'Operators',
          url: '/users/operator',
          icon: icon.operator,
          visible: () => !ConferenceHelper.isConferenceExpired() && SecurityHelper.canExecuteActionOnResource("user.list", { RolesString: ROLE_OPERATOR_ID })
        },  
        {
          name: 'Certificates',
          icon: icon.certificate,
          tag: "certificates",
          url: '/templates/certificate',
          visible: () => {
            return ConferenceHelper.isFeatureEnabled("certificates")
          }
        },
        {
          name: 'CEU Reports',
          icon: icon.transcript,
          tag: "transcripts",
          url: '/templates/transcript',
          visible: () => {
            return ConferenceHelper.isFeatureEnabled("transcripts")
          } 
        },
      ],
    },
    {
      name: 'Feedback',
      icon: icon.questionnaires,
      tag: "feedback",
      visible: !ConferenceHelper.isConferenceExpired(),
      children: [ 
        {
          name: 'Session Evaluation',
          url: '/sessionevaluation',
          icon: icon.session_evaluation,
          lock: !PackageHelper.isAttendeeEnabled() && SecurityHelper.isAdministrator(),
          visible: SecurityHelper.canExecuteAction("sessionsurvey.getoptions")
        },
        {
          name: 'My Session Evaluations',
          url: '/mysessionevaluation',
          icon: icon.session_evaluation,
          visible: () => {
            return SecurityHelper.isPresenter()  && !SecurityHelper.isAdministrator()  
                  && SecurityHelper.getSettings().IsEnableSessionsEvaluationsForPresenters
          }
        },  
        {
          name: 'Polls',
          url: '/polls',
          icon: icon.polls,
          visible: ["survey.list"],
          lock: !PackageHelper.isAttendeeEnabled() && SecurityHelper.isAdministrator(),
          tag: "feedback",
        },
        { 
          name: 'Surveys',
          url: '/surveys',
          icon: icon.surveys, 
          tag: "feedback",
          lock: !PackageHelper.isAttendeeEnabled() && SecurityHelper.isAdministrator(),
          visible: !ConferenceHelper.isConferenceExpired() && SecurityHelper.canExecuteAction("survey.list") && SecurityHelper.canExecuteAction("conference.save"),
        },    
        {
          name: 'Conference Evaluation',
          url: '/conferenceevaluation/dashboard',
          icon: icon.conference_evaluation,
          lock: !PackageHelper.isAttendeeEnabled() && SecurityHelper.isAdministrator(),
          visible: SecurityHelper.isAdministrator() && !ConferenceHelper.isConferenceExpired(),
        },
        {
          name: 'Live Polls',
          url: '/mypolls',
          icon: icon.polls,
          visible: () => {
            return !SecurityHelper.isAdministrator() && SecurityHelper.isAttendee() && !ConferenceHelper.isConferenceExpired() && SecurityHelper.getSettings().PollsEnabled
          }
        },
        {
          name: 'Surveys',
          url: '/mysurveys',
          icon: icon.surveys,
          visible: () => {
            return !SecurityHelper.isAdministrator() && SecurityHelper.isAttendee() && !ConferenceHelper.isConferenceExpired() && SecurityHelper.getSettings().SurveysEnabled
          }
        },
        {
          name: 'Session Evaluations',
          url: '/evaluations',
          icon: icon.session_evaluation,
          visible: () => {
            return !SecurityHelper.isAdministrator() && SecurityHelper.isAttendee() && !ConferenceHelper.isConferenceExpired() && SecurityHelper.getSettings().SessionSurveyEnabled
          }
        },
        {
          name: 'Conference Evaluation',
          url: '/myconferenceevaluation',
          icon: icon.conference_evaluation,
          visible: () => {
            return SecurityHelper.getSettings().ConferenceEvaluationsEnabled && !SecurityHelper.isAdministrator() && SecurityHelper.isAttendee()
          }
        }
      ],
    },
    {
      name: 'Network',
      icon: icon.social_feed,
      tag: 'social',
      lock: !PackageHelper.isAttendeeEnabled() && SecurityHelper.isAdministrator(),
      children: [
        {
          name: 'People',
          url: '/attendees',
          icon: icon.attendee,
          visible: !SecurityHelper.isExhibitorOnly()
        },
        {
          name: 'Messages',
          url: '/messages',
          icon: icon.message,
        },
        {
          name: 'Appointments',
          url: '/appointments',
          icon: icon.appointment,
        },
        {
          name: 'Social Wall',
          url: '/social/wall',
          icon: icon.social_feed,
          visible: () => (SecurityHelper.isAdministrator()
            || SecurityHelper.isPresenter()
            || SecurityHelper.isModerator()) && !SecurityHelper.getSettings().HideSocialActivities
        },
        {
          name: 'Create a Post',
          url: '/social/post',
          icon: icon.post_to_social_feed,
          visible: () => (SecurityHelper.isAdministrator()
            || SecurityHelper.isPresenter()
            || SecurityHelper.isModerator()) && !SecurityHelper.getSettings().HideSocialActivities
        },
        {
          name: 'Moderate Posts',
          url: '/social/moderate',
          icon: icon.moderate_interactions,
          visible: SecurityHelper.canExecuteAction("interaction.listtomoderate") && !SecurityHelper.getSettings().HideSocialActivities
        },
        {
          name: 'Moderators',
          url: '/users/moderator',
          lock: !PackageHelper.isAttendeeEnabled() && SecurityHelper.isAdministrator(),
          icon: icon.moderator,
          visible: () => {
            return SecurityHelper.canExecuteActionOnResource("user.list", { RolesString: ROLE_MODERATOR_ID })
          }
        },
        {
          name: 'Gallery',
          url: '/social/gallery',
          icon: icon.gallery,
          visible: (SecurityHelper.canExecuteAction("interaction.listimages") || SecurityHelper.canExecuteAction("export.socialmedia")) && !SecurityHelper.getSettings().HideSocialActivities
        },
      ],
    },  
    {
      name: 'Analytics',
      icon: icon.analytics,
      tag: "analytics",
      children: [
        {
          name: 'Attendee Registration',
          url: '/analytics/registration/attendee',
          icon: icon.edit,
          lock: !PackageHelper.isPreConferenceEnabled() && SecurityHelper.isAdministrator(),
          visible: ["registration.summaryreport"]
        },
        {
          name: 'Exhibitor Registration',
          url: '/analytics/registration/exhibitor',
          icon: icon.edit,
          lock: (!PackageHelper.isPreConferenceEnabled() || !PackageHelper.isExhibitorEnabled()) && SecurityHelper.isAdministrator(),
          visible: ["registration.summaryreport"]
        },
        {
          name: 'Contact Information',
          url: '/users/attendee/analytics',
          icon: icon.attendee,
          visible: ["attendee.contactinfostats"]
        },
        {
          name: 'Attendance',
          url: '/attendance/report',
          icon: icon.attendance,
          visible: ["report.query"]
        },
        {
          name: 'Social Activity',
          url: '/analytics/social',
          icon: icon.social,
          lock: !PackageHelper.isAttendeeEnabled() && SecurityHelper.isAdministrator(),
          visible: SecurityHelper.canExecuteAction("report.query")
        },
        {
          name: 'Leads',
          url: '/analytics/leads',
          icon: icon.leads,
          lock: !PackageHelper.isExhibitorEnabled() && SecurityHelper.isAdministrator(),
          visible: SecurityHelper.canExecuteAction("lead.reportquery")
        },
        {
          name: 'Interested Attendees',
          url: '/analytics/interested',
          icon: "fas fa-wave-square",
          visible: SecurityHelper.canExecuteAction("lead.reportquery") && SecurityHelper.isExhibitor()
        },
        {
          name: 'Session Evaluation',
          url: '/sessionevaluation/summaryreport',
          icon: icon.session_evaluation,
          lock: !PackageHelper.isAttendeeEnabled() && SecurityHelper.isAdministrator(),
          visible: SecurityHelper.canExecuteAction("sessionsurvey.getoptions")
        },
      ],
    },
    {
        name: 'Help Desk',
        icon: icon.help,
        tag: 'support', 
        url: '/support/tickets',
        alwaysIcon: true
    },
    {
      name: 'Advanced Options',
      icon: icon.advanced_options,
      tag: "advanced-options",
      children: [
        {
          name: 'Administrators',
          url: '/users/administrator',
          icon: icon.administrator,
          visible: () => SecurityHelper.canExecuteActionOnResource("user.list", { RolesString: ROLE_ADMIN_ID })
        },
        {
          name: 'Import Data',
          url: '/import/upload',
          icon: icon.import_data,
          visible: () => SecurityHelper.canExecuteAction("import") && !ConferenceHelper.isConferenceExpired()
        },
        {
          name: 'Export Data',
          url: '/export/download',
          icon: icon.export_data,
          visible: ["export.attendees", "export.operators", "export.workshops", "export.leads", "export.rawswipes", "export.attendancelogs", "export.attendancelogswithdetails"]
        },
        {
          name: 'Custom Qualifiers',
          url: '/leads/form',
          icon: icon.edit,
          visible: () => SecurityHelper.canExecuteAction("lead.saveform") && !SecurityHelper.isAdministratorOnly() && !ConferenceHelper.isConferenceExpired()
        },
        {
          name: 'Update my Company',
          url: '/companies/mine',
          icon: icon.company,
          visible: () => SecurityHelper.canExecuteAction("company.savemine") && !SecurityHelper.isAdministratorOnly() && !ConferenceHelper.isConferenceExpired()
        }, 
        {
          name: 'Email Delivery Logs',
          url: '/emaildeliverylogs',
          icon: icon.email,
          visible: () => SecurityHelper.canExecuteAction("emaildeliverylog.list") && !ConferenceHelper.isConferenceExpired()
        }, 
        {
          name: 'User Sessions',
          url: '/usersessions',
          icon: icon.user_sessions,
          visible: () => SecurityHelper.canExecuteAction("session.list") && !ConferenceHelper.isConferenceExpired()
        },
        {
          name: 'Devices',
          url: '/devices',
          icon: icon.device,
          visible: () => SecurityHelper.canExecuteAction("device.list") && !ConferenceHelper.isConferenceExpired()
        },
        {
          name: 'Settings',
          url: '/conference/settings',
          icon: icon.settings,
          visible: () => SecurityHelper.canExecuteAction("setting.save") && !ConferenceHelper.isConferenceExpired()
        }
      ],
    },
    {
      name: SecurityHelper.getUser()?.FullName,
      icon: icon.user,
      visible: !SecurityHelper.isAdministratorOnly(),
      tag: 'profile',
      children: [
        {
          name: 'Profile',
          url: '/profile',
          icon: icon.user,
        },
        {
          name: 'Attendance',
          url: '/myattendance',
          icon: icon.attendance,
          visible: SecurityHelper.isAttendee()
        },
        {
          name: 'Registered Sessions',
          url: '/registrations',
          icon: icon.registrations,
          visible: SecurityHelper.isAttendee()
        },
        {
          name: 'Notes',
          url: '/notes',
          icon: icon.note,
        },
        {
          name: 'My Files',
          url: '/files',
          icon: icon.file,
        },
      ]
    }
  ]
}
