import * as icon from '../assets/Icons'
import SecurityHelper from '../helpers/SecurityHelper';
import PackageHelper from '../helpers/PackageHelper';
import { isAndroid, isiOS } from '../helpers/CompatibilityHelper';
import conferenceHelper from '../helpers/ConferenceHelper';
import scheduleHelper from '../helpers/ScheduleHelper'

export default function attendee() {

  return [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: icon.dashboard,
      alwaysIcon: true
    },
    {
      name: 'Conference',
      icon: icon.conference,
      tag: "conference",
      visible: !conferenceHelper.isConferenceExpired(),
      children: [
        {
          name: 'Sessions',
          url: '/sessions',
          icon: icon.workshop,
        },
        {
          name: 'Schedule at a Glance',
          url: '/schedule',
          icon: icon.calendar_week,
          visible: scheduleHelper.publicationStatus,
        },
        {
          name: 'Presenters',
          url: '/presenters',
          icon: icon.presenter,
        },
        {
          name: 'Sponsors',
          url: '/sponsors',
          icon: icon.sponsorship,
          visible: PackageHelper.isExhibitorEnabled() && SecurityHelper.getSettings().SponsorsEnabled
        },
        {
          name: 'Exhibitors',
          url: '/exhibitors',
          icon: icon.company,
          visible: PackageHelper.isExhibitorEnabled()
        },
        {
          name: 'Virtual Exhibit Hall',
          url: '/exhibitors/virtual',
          icon: icon.virtual_exhibit,
          visible: PackageHelper.isExhibitorEnabled() && SecurityHelper.getSettings().VirtualExhibitEnabled
        },
        {
          name: 'Announcements',
          url: '/announcements',
          icon: icon.announcement,
        },
        {
          name: 'Maps',
          url: '/maps',
          icon: icon.map,
        },
        {
          name: 'Info',
          url: '/info',
          icon: icon.conference,
        },
      ]
    },
    {
      name: 'Network',
      icon: icon.social_feed,
      tag: 'social',
      children: [
        {
          name: 'People',
          url: '/attendees',
          icon: icon.attendee,
        },
        {
          name: 'Messages',
          url: '/messages',
          icon: icon.message,
        },
        {
          name: 'Appointments',
          url: '/appointments',
          icon: icon.appointment,
        },
      ]
    },
    {
      name: 'Feedback',
      icon: icon.questionnaires,
      tag: 'questionnaires',
      visible: !conferenceHelper.isConferenceExpired(),
      children: [
        {
          name: 'Live Polls',
          url: '/mypolls',
          icon: icon.polls,
          visible: () => {
            return SecurityHelper.getSettings().PollsEnabled
          }
        },
        {
          name: 'Surveys',
          url: '/mysurveys',
          icon: icon.surveys,
          visible: () => {
            return SecurityHelper.getSettings().SurveysEnabled
          }
        },
        {
          name: 'Session Evaluations',
          url: '/evaluations',
          icon: icon.session_evaluation,
          visible: () => {
            return SecurityHelper.getSettings().SessionSurveyEnabled
          }
        },
        {
          name: 'Conference Evaluation',
          url: '/myconferenceevaluation',
          icon: icon.conference_evaluation,
          visible: () => {
            return SecurityHelper.getSettings().ConferenceEvaluationsEnabled
          }
        },
      ]
    },
    {
      name: 'Help Desk',
      icon: icon.help,
      tag: 'support', 
      url: '/support/tickets',
      alwaysIcon: true
    },
    {
      name: SecurityHelper.getUser()?.FullName,
      icon: icon.user,
      tag: 'profile',
      children: [
        {
          name: 'Profile',
          url: '/profile',
          icon: icon.user,
        },
        {
          name: 'Attendance',
          url: '/myattendance',
          icon: icon.attendance,
        },
        {
          name: 'Registered Sessions',
          url: '/registrations',
          icon: icon.registrations,
        },
        {
          name: 'My Tickets',
          url: '/tickets',
          icon: icon.ticket,
        },
        {
          name: 'Notes',
          url: '/notes',
          icon: icon.note,
        },
        {
          name: 'My Files',
          url: '/files',
          icon: icon.file,
        },
      ]
    },
    {
      name: 'Attendee App',
      url: isiOS ? 'https://apps.apple.com/us/app/conference-attendee/id1119188069' : 'https://play.google.com/store/apps/details?id=com.engineerica.conferencetrackerattendees',
      icon: icon.mobile,
      variant: 'attendee-app',
      visible: isiOS || isAndroid,
      alwaysIcon: true,
      attributes: { target: '_blank', rel: "noopener" },
    }
  ]
}
